import Constants from "elements/gantt/constants";
import { IGanttController } from "elements/gantt/controller";
import { formatColumnOptions } from "elements/gantt/toolbar/custom-rows/column-utils";
import { MondayColumn } from "frontend/hooks/use-board-integrations.types";
import { useEffect } from "react";
import { usePrevious } from "react-use";

/**
 * Custom hook for managing Gantt custom rows.
 * @param {Object} params - The parameters for the hook.
 * @param {IGanttController} params.controller - The Gantt controller to manage state and layout.
 * @param {Function} params.getItem - Function to fetch data for an item by ID and integration ID.
 * @param {Array<any>} params.deps - Dependencies for triggering the useEffect.
 */
export const useGanttCustomRows = ({
  controller,
  getItem,
  deps,
}: {
  controller: IGanttController;
  getItem: (
    id: string,
    integrationId: string
  ) => {
    columns: MondayColumn[];
    id: string;
    name: string;
    columnValues: any[];
  } | null;
  deps: any[];
}) => {
  // Get the previous value of `controller.element.customRows` for comparison.
  const previousCustomRows = usePrevious(controller.element.customRows);

  useEffect(() => {
    // If custom rows were removed, reset the layout.
    if (previousCustomRows && !controller.element.customRows) {
      controller.setLayoutBasedOnColumns([], new Map());
      return;
    }

    // Fetch task cells from the controller.
    const tasks = controller.getTaskCells();

    // Map tasks to extract essential details, including external data for integration items.
    const sortedTasks = tasks.map((task) => ({
      id: task.id,
      type: task.element.type,
      integrationId: task.element.type === "integrationItem" ? task.element.integrationId : null,
      externalData:
        task.element.type === "integrationItem"
          ? getItem(task.element.configuration.itemId, task.element.integrationId)
          : null,
    }));

    // Parse integration ID and column ID from the custom rows' ID.
    const [integrationId, ...separatedColumnId] = controller.element.customRows?.id.split("_") ?? [];
    const columnId = separatedColumnId.join("_");

    // Find the integration columns matching the integration ID.
    const integrationColumns = sortedTasks.find((config) => config.integrationId === integrationId);

    // If no matching integration columns, exit early.
    if (!integrationColumns) {
      return;
    }

    // Find the selected column configuration from external data.
    const selectedConfig = integrationColumns.externalData?.columns.find((column) => column.id === columnId);
    if (!selectedConfig) {
      return;
    }

    // Prepare split options, including an "Other" category.
    const splitOptions = [
      ...formatColumnOptions(selectedConfig),
      { id: "-1", title: "Other", color: Constants.LayoutDateHeaderFill },
    ];

    // Map each task to a split ID based on matching column values.
    const taskToSplitId = new Map<string, string>();
    for (const task of sortedTasks) {
      if (task.type !== "integrationItem" || task.integrationId !== integrationId) {
        taskToSplitId.set(task.id, "-1");
        continue;
      }

      // Find the relevant split option based on column values.
      const relevantSplit = splitOptions.find((split) =>
        task.externalData?.columnValues.some((column) =>
          column.type === "status" ? column.value.text === split.title : false
        )
      );

      // Assign the relevant split ID or default to "Other".
      taskToSplitId.set(task.id, relevantSplit ? relevantSplit.id : "-1");
    }

    // Update the layout if new custom rows are defined.
    if (!previousCustomRows && controller.element.customRows?.id) {
      controller.setLayoutBasedOnColumns(splitOptions, taskToSplitId);
    }
  }, [JSON.stringify(controller.element.customRows), getItem, deps]);
};
