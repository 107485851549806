import { BaseElementController } from "elements/base/controller";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { updateIntegrationItem } from "frontend/api";
import { IntegrationItem } from "shared/datamodel/schemas";
import { cleanDate, getRowColor } from "../utils";
import { getFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { IGanttController } from "elements/gantt/controller";
import { TaskColor } from "elements/gantt/constants";

export default class GanttMondayItemCellController
  extends BaseElementController<IntegrationItem>
  implements IGanttBaseCellController<IntegrationItem>
{
  #ganttController: WeakRef<IGanttController> | undefined;

  #startDateColumnId: string | null | undefined = null;
  #dependencyId: string | null | undefined = null;
  #endDateColumnId: string | null | undefined = null;
  #url: string | null | undefined = null;
  #dependencies: string[] | undefined | null = null;
  #isLoading: boolean = false;
  #isInitialLoading: boolean = true;
  private mondayUpdateDebounceTimeout: NodeJS.Timeout | null = null;
  #lastUpdateToMonday: number = Date.now();

  async updateIntegrationConfig(config?: {
    dependencyId?: string;
    endId?: string;
    startId?: string;
    url?: string;
    dependencies?: string[];
  }) {
    if (!config) {
      return;
    }
    this.#startDateColumnId = config.startId;
    this.#endDateColumnId = config.endId;
    this.#dependencyId = config.dependencyId;
    this.#dependencies = config.dependencies;
    this.#url = config.url;

    this.#isInitialLoading = false;
    this.handleDependencies(config.dependencies ?? []);
    this.notify();
  }

  updateElement(element: IntegrationItem): void {
    super.updateElement(element);
    this.notify();
  }

  updateController(ganttController: IGanttController): void {
    this.#ganttController = new WeakRef(ganttController);
    this.notify();
  }

  getLayout() {
    return this.#ganttController?.deref()?.getTaskCellLayout(this.id);
  }

  getTitle() {
    return this.element.fieldValues?.["title"] || "";
  }

  getBackgroundColor() {
    if (this.#ganttController?.deref()?.element.customRows) {
      return this.element.fill as TaskColor;
    }
    return getRowColor(this.element.fill ?? "");
  }

  getGanttStartDate() {
    const cell = this.#ganttController?.deref()?.getDateColumnsLayout()[0];
    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getGanttEndDate() {
    const cell = this.#ganttController
      ?.deref()
      ?.getDateColumnsLayout()
      .filter((cell) => !cell.isHeader)
      .at(-1);

    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getStartDate() {
    return cleanDate(this.element.fieldValues?.["fromDate"] ?? Date.now());
  }

  getEndDate() {
    return cleanDate(this.element.fieldValues?.["toDate"] ?? Date.now());
  }

  changeTitle(newTitle: string) {
    updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      name: newTitle,
    });
  }

  isSelected() {
    return this.#ganttController?.deref()?.context.selectedElementIds.includes(this.id) ?? false;
  }

  getAttachedConnectors() {
    return new Set(
      this.#ganttController
        ?.deref()
        ?.getConnectors()
        .filter((c) => c.to.id === this.id || c.from.id === this.id)
        .flatMap((c) => [c.from.id, c.to.id])
    );
  }

  /**
   * Handles dependencies for the current task, updating connectors in the Gantt chart.
   *
   * This function ensures that connectors in the Gantt chart are consistent with the provided
   * dependencies. It identifies new dependencies that need to be added and old ones that need
   * to be removed, then performs the necessary updates.
   *
   * @param dependencies - An array of item IDs representing the dependencies for the current task.
   */
  async handleDependencies(dependencies: string[]) {
    const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
    if (!shouldHandleDependencies) {
      return;
    }
    // Collect all connectors currently attached to this task
    const connectorsAttached = this.getAttachedConnectors();

    // Maps for converting between item IDs and task IDs
    const idsMappings = this.#ganttController?.deref()?.getIdsMappings();
    if (!idsMappings) {
      return;
    }
    const { itemIdToTaskId, taskIdToItemId } = idsMappings;

    // Identify new and removable connectors
    const dependenciesSet = new Set(dependencies);
    const newConnectors = [...dependenciesSet].filter((itemId) => itemIdToTaskId.has(itemId));
    const removableConnectors = [...connectorsAttached].filter(
      (taskId) => !dependenciesSet.has(taskIdToItemId.get(taskId) || "")
    );

    // Add new connectors
    await Promise.all(
      newConnectors.map(async (itemId) => {
        const taskId = itemIdToTaskId.get(itemId);
        if (taskId && !connectorsAttached.has(taskId)) {
          return this.#ganttController?.deref()?.addConnector(taskId, this.id, "monday");
        }
      })
    );
    this.notify();

    // Remove outdated connectors
    await Promise.all(
      removableConnectors.map((taskId) => {
        const connectors =
          this.#ganttController
            ?.deref()
            ?.getConnectors()
            .filter((c) => c.from.id === taskId && c.to.id === this.id) ?? [];
        return Promise.all(connectors.map((c) => this.#ganttController?.deref()?.removeConnector(c.id)));
      })
    );
    this.notify();
  }

  async updateReflectUsingPusher(
    data?:
      | {
          itemId: number;
          columnValues?: {
            id: string;
            type: string;
            value: string | { linkedPulseIds: { linkedPulseId: number }[] };
          }[];
          name: string;
        }
      | null
      | undefined
  ) {
    let eventFromDate: string | number | undefined =
      data?.columnValues &&
      data?.columnValues?.find((column) => column.id === this.#startDateColumnId)?.value.toString();
    if (eventFromDate) {
      eventFromDate = new Date(eventFromDate).getTime();
    }

    let eventToDate: string | number | undefined =
      data?.columnValues && data?.columnValues?.find((column) => column.id === this.#endDateColumnId)?.value.toString();
    if (eventToDate) {
      eventToDate = new Date(eventToDate).getTime();
    }

    const title = data?.name ?? this.element.fieldValues?.["title"];
    const fromDate = cleanDate(eventFromDate ?? this.element.fieldValues?.["fromDate"]).getTime();
    const toDate = cleanDate(eventToDate ?? this.element.fieldValues?.["toDate"]).getTime();
    const dependenciesDataValue = data?.columnValues?.find((column) => column.id === this.#dependencyId)?.value;

    const dependencies: string[] =
      typeof dependenciesDataValue === "object"
        ? (dependenciesDataValue?.linkedPulseIds ?? []).map((d: { linkedPulseId: number }) =>
            d.linkedPulseId.toString()
          )
        : [];

    if (data) {
      await this.patchElement(this.id, (draft: IntegrationItem) => {
        draft.fieldValues ??= {};
        draft.fieldValues["title"] = title;

        if (fromDate <= toDate) {
          draft.fieldValues["fromDate"] = fromDate;
          draft.fieldValues["toDate"] = toDate;
        }

        dependenciesDataValue && this.handleDependencies(dependencies);
      });
    }
  }

  async updateIntegration(
    updateUsing: "pusher" | "monday",
    data?:
      | {
          itemId: number;
          columnValues?: {
            id: string;
            type: string;
            value: string | { linkedPulseIds: { linkedPulseId: number }[] };
          }[];
          name: string;
        }
      | null
      | undefined
  ) {
    this.#isLoading = true;

    if (updateUsing === "pusher") {
      const differenceInSeconds = Math.abs(this.#lastUpdateToMonday - Date.now()) / 1000;
      if (differenceInSeconds > 1.5) {
        await this.updateReflectUsingPusher(data);
      }
    }

    if (updateUsing === "monday") {
      setTimeout(() => {
        this.updateMonday(this.element.integrationId, this.element.configuration.itemId);
      }, 1000);
    }

    this.#isLoading = false;
  }

  async updateMonday(integrationId: string, itemId: string) {
    const payload: Record<string, string> = {};

    const fromDate = this.element.fieldValues?.["fromDate"];
    if (fromDate && this.#startDateColumnId) {
      payload[this.#startDateColumnId] = new Date(fromDate).toLocaleDateString("en-CA");
    }

    const toDate = this.element.fieldValues?.["toDate"];
    if (toDate && this.#endDateColumnId) {
      payload[this.#endDateColumnId] = new Date(toDate).toLocaleDateString("en-CA");
    }

    if (Object.keys(payload).length === 0) {
      this.#isLoading = false;
      return;
    }

    if (this.mondayUpdateDebounceTimeout) {
      clearTimeout(this.mondayUpdateDebounceTimeout);
    }

    this.mondayUpdateDebounceTimeout = setTimeout(async () => {
      this.#isLoading = true;
      this.#lastUpdateToMonday = Date.now();
      await updateIntegrationItem(integrationId, itemId, payload);
      this.#isLoading = false;
    }, 1000);
  }

  isLoading(): boolean {
    return this.#isLoading || this.#isInitialLoading;
  }

  isInitialLoading(): boolean {
    return this.#isInitialLoading;
  }

  getLink() {
    return this.#url || "";
  }

  isIntegrated(): boolean {
    return this.#startDateColumnId !== null && this.#endDateColumnId !== null && this.#dependencyId !== null;
  }

  async addMondayIntegrationConnector(itemId: string | number) {
    const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
    if (!shouldHandleDependencies) {
      return;
    }
    if (!this.#dependencyId) {
      return;
    }

    const previousDependencies = this.#dependencies ?? [];
    const dependencies = [
      ...previousDependencies.map((id) => {
        return { linkedPulseId: id };
      }),
      { linkedPulseId: itemId },
    ];

    await updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      [this.#dependencyId]: { linkedPulseIds: dependencies },
    });
    this.notify();
  }

  async removeMondayIntegrationConnector(itemId: string | number) {
    const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
    if (!shouldHandleDependencies) {
      return;
    }

    if (!this.#dependencyId) {
      return;
    }

    const previousDependencies = this.#dependencies ?? [];
    const dependencies = previousDependencies
      .filter((id) => id !== itemId)
      .map((id) => {
        return { linkedPulseId: id };
      });

    await updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      [this.#dependencyId]: { linkedPulseIds: dependencies },
    });
    this.notify();
  }
}
