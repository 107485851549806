import { differenceInMilliseconds } from "date-fns";
import type { IGanttController } from "elements/gantt/controller";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { combineMappingWithData } from "elements/gantt/controllers/controller-utils";
import { useBoardTasks } from "frontend/hooks/use-board-integrations";
import { equals, groupBy, isEmpty } from "rambda";
import { useCallback, useEffect, useState } from "react";
import { IntegrationItem } from "shared/datamodel/schemas";

export const useGanttSyncMondayItems = ({ controller }: { controller: IGanttController }) => {
  const [integrationsConfigs, setIntegrationsConfigs] = useState<Record<
    string,
    {
      dependencyId?: string;
      endId?: string;
      startId?: string;
      url?: string;
      dependencies?: string[];
    }
  > | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [mondayTasks, setMondayTasks] = useState<{ [index: string]: IGanttBaseCellController<IntegrationItem>[] }>({});

  const pusherChannel = controller.context.pusherChannel;

  const { fetchItems, getBoardIntegrationConfig, dataSource, loadingBoardIntegrationsState } = useBoardTasks(
    controller.context.documentId,
    pusherChannel
  );

  useEffect(() => {
    const setTasks = () => {
      const tasks = controller
        .getTaskCells()
        .filter((t) => t.element.type === "integrationItem") as IGanttBaseCellController<IntegrationItem>[];

      const tasksByIntegratinId = groupBy(
        (task: IGanttBaseCellController<IntegrationItem>) => task.element.integrationId
      )(tasks);
      if (!equals(tasksByIntegratinId, mondayTasks) && !isEmpty(tasksByIntegratinId)) {
        setMondayTasks(tasksByIntegratinId);
      }
    };
    controller.subscribe(setTasks);

    return () => controller.unsubscribe(setTasks);
  }, [controller, mondayTasks]);

  const getData = useCallback(async () => {
    const mondayTasksIntegrationsToTasks = mondayTasks;
    let requestPayload = {};
    for (const [integrationId, tasks] of Object.entries(mondayTasksIntegrationsToTasks)) {
      const itemIds = tasks.map((t) => t.element.configuration.itemId);
      requestPayload = {
        ...requestPayload,
        [integrationId]: {
          itemIds: itemIds,
        },
      };
      getBoardIntegrationConfig(integrationId);
    }
    if (isEmpty(requestPayload) || !isFirstLoad) {
      return;
    }
    await fetchItems(requestPayload);
    setIsFirstLoad(true);
  }, []);

  useEffect(() => {
    getData();
  }, [controller.getTaskCells().length, loadingBoardIntegrationsState]);

  useEffect(() => {
    if (!isEmpty(dataSource) && !isEmpty(mondayTasks)) {
      for (const [integrationId, { items }] of Object.entries(dataSource)) {
        const integrationConfig = getBoardIntegrationConfig(integrationId);
        for (const item of items) {
          if (integrationConfig) {
            const newTaskData = combineMappingWithData(integrationConfig.config.columnMappings, item);
            const task = (mondayTasks[integrationId] ?? []).find((t) => t.element.configuration.itemId === item.id);
            if (task?.isInitialLoading?.()) {
              controller.context.undoRedoStack.patchElement(task.id, (draft: IntegrationItem) => {
                const hasJustBeenPlaces = differenceInMilliseconds(draft.fieldValues?.placedAt, Date.now()) < 100;
                draft.fieldValues ??= {};
                if (
                  newTaskData.startDate &&
                  newTaskData.endDate &&
                  newTaskData.startDate <= newTaskData.endDate &&
                  !hasJustBeenPlaces
                ) {
                  draft.fieldValues["fromDate"] = newTaskData.startDate;
                  draft.fieldValues["toDate"] = newTaskData.endDate;
                }
                delete draft.fieldValues["placedAt"];
                draft.fieldValues["title"] = newTaskData.title;
              });
            }
            if (!task) {
              continue;
            }
            setIntegrationsConfigs((prev) => {
              return {
                ...prev,
                [task.id]: {
                  dependencyId: newTaskData.dependencyId,
                  endId: newTaskData.endId,
                  startId: newTaskData.startId,
                  dependencies: newTaskData.dependencies,
                  url: item.url,
                },
              };
            });
          }
        }
      }
      setIsFirstLoad(false);
      controller.notify();
    }
  }, [JSON.stringify(dataSource), mondayTasks, loadingBoardIntegrationsState]);

  useEffect(() => {
    if (!integrationsConfigs) {
      return;
    }
    for (const [_, tasks] of Object.entries(mondayTasks)) {
      for (const task of tasks) {
        const config = integrationsConfigs[task.id];
        task.updateIntegrationConfig?.(config);
      }
    }
  }, [integrationsConfigs, loadingBoardIntegrationsState]);
};
